import Backoffice from "@snark/backoffice";

const context = {
    brand: Backoffice.populator("brand")
};

export default {
    name: "model",
    collection: "model",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Modèles",
        "singleTitle": "Modèle"
    },
    emptyItem: {},
    listTextFilter: ['name'],
    sort: {
        back: [
            {field: "name"}
        ]
    },
    loadList: loadList,
    loadSingle: loadSingle,
    listColumns: [
        {
            name: "name",
            label: "Nom"
        },
        {
            name: "brandId",
            label: "Marque",
            formatter: Backoffice.formatters.linkObjectId("brand", {
                label: context.brand.formatter(),
            })
        },
    ],
    fields: [
        {
            name: "_id",
            label: "Identifiant",
            readOnly: true,
            hideOnCreate: true
        },
        {
            name: "name",
            label: "Nom"
        },
        {
            name: "brandId",
            label: "Marque",
            formatter: Backoffice.formatters.linkObjectId("brand", {
                label: context.brand.formatter(),
            }),
            editor: context.brand.editor()
        }
    ],
};

function loadList() {
    return Promise.all([context.brand.load()]);
}

function loadSingle() {
    return Promise.all([context.brand.load()]);
}