import Backoffice from "@snark/backoffice";

export default {
	name: "testimony",
	collection: "testimony",
	section: "Website",
	idProperty: "_id",
	labels: {
		"listTitle": "Témoignages",
		"singleTitle": "Témoignage"
	},
	emptyItem: {
		published: true
	},
	listColumns: [
		{
			"name": "firstname",
			"label": "Prénom"
		},
		{
			"name": "company",
			"label": "Compagnie"
		},
		{
			"name": "text",
			"label": "Texte"
		},
		{
			"name": "published",
			"label": "Publié",
			formatter: Backoffice.formatters.boolean(['❌', '✅'])
		}
	],
	fields: [
		{
			"name": "firstname",
			"label": "Prénom"
		},
		{
			"name": "company",
			"label": "Compagnie"
		},
		{
			"name": "text",
			"label": "Texte"
		},
		{
			"name": "published",
			"label": "Publié",
			formatter: Backoffice.formatters.boolean(['❌', '✅']),
			editor: {
				type: "boolean"
			}
		}
	]
};
