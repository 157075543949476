import Backoffice from "@snark/backoffice";

let crud = {
    name: "press",
    collection: "press",
    section: "Website",
    idProperty: "_id",
    labels: {
        "listTitle": "Presses",
        "singleTitle": "Presse"
    },
    emptyItem: {
    },
    listColumns: [
        {
            "name": "logo",
            "label": "Logo",
            formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({
                urlPrefix: process.env.VUE_APP_CDN_URL,
                maxHeight: 100
            }))
        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "url",
            "label": "URL"
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "url",
            "label": "URL"
        },
        {
            "name": "logo",
            "label": "Logo",
            formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({
                urlPrefix: process.env.VUE_APP_CDN_URL,
                maxHeight: 200
            })),
            editor: {
                type: 'image',
                preview: {
                    width: 256,
                    height: 256,
                    formatUrl: Backoffice.formatters.subProperty('path'),
                    urlPrefix: process.env.VUE_APP_CDN_URL
                },
                accept: "image/png",
                treatment: {
                    width: 512,
                    height: 512,
                    type: 'resize-contains',
                    format: 'image/png'
                }
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};

export default crud;
