<script>
import Vue from "vue";

export default {
  name: "FullBusRich",
  props: [
    'propertyName',
    'placeholder',
    'value',
    'options'
  ],
  data() {
    return {
      cdn: process.env.VUE_APP_CDN_URL,
      currentBlock: "block-title",
      blockTypes: [
        {
          label: "Titre",
          value: "block-title",
        },
        {
          label: "Paragraphe",
          value: "block-paragraph",
        },
        {
          label: "Image",
          value: "block-image",
        },
      ],
      blocks: {
        "block-title": "field-input",
        "block-paragraph": "field-rich-text",
        "block-image": "field-image",
      }
    }
  },
  watch: {
    "currentBlock"() {
      if (this.currentBlock === 'block-image' && !this.$attrs.object.image) {
        this.$attrs.object.image = {path: ''};
      }
      this.$attrs.object.blockName = this.currentBlock;
    },
    "$attrs.object.blockName"() {
      this.currentBlock = this.$attrs.object.blockName || this.currentBlock;
    }
  },
  methods: {
  },
  mounted() {
    this.currentBlock = this.$attrs.object && this.$attrs.object.blockName || this.currentBlock;
    if (!this.$attrs.object.blockName) {
      this.$attrs.object.blockName = this.currentBlock;
    }
  }
}
</script>
<template>
  <div class="FullBusRich">
    <field-select v-bind="{...$props, options: {options: blockTypes}}" v-model="currentBlock" />
    <template v-if="currentBlock === 'block-image'">
      <component :is="blocks[currentBlock]" v-bind="$props" :options="{preview: {urlPrefix: cdn, width: 300, height: 200}}" v-model="$attrs.object.image.path" />
      <div class="Field imgHeight">
        <label>Hauteur de l'image (un nombre, en pixel, ex: "400")</label>
        <input v-model="$attrs.object.image.size">
      </div>
    </template>
    <template v-else>
      <component :is="blocks[currentBlock]" v-bind="$props" v-model="$attrs.object.text" />
    </template>
  </div>
</template>
<style lang="scss">
.FullBusRich {
  display: flex;

  .imgHeight {
    margin-left: 15px;
  }

  .FieldSelect {
    min-width: 100px;
    max-width: 100px;
    margin-right: 10px;

    & + div {
      width: 100%;

      &.FieldImage {
        width: auto;
      }
    }
  }
}
</style>