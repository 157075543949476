export default {
    name: "brand",
    collection: "brand",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Marques",
        "singleTitle": "Marque"
    },
    emptyItem: {
    },
    listTextFilter: ['name'],
    sort: {
        back: [
            {field: "name"}
        ]
    },
    listColumns: [
        {
            name: "name",
            label: "Nom"
        }
    ],
    fields: [
        {
            name: "_id",
            label: "Identifiant",
            readOnly: true,
            hideOnCreate: true
        },
        {
            name: "name",
            label: "Nom"
        }
    ],
};
