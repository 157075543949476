import Backoffice from "@snark/backoffice";

export default {
    name: "brochure",
    collection: "brochure",
    section: "Website",
    idProperty: "_id",
    noCreate: true,
    labels: {
        "listTitle": "Demandes de brochure",
        "singleTitle": "Demande de brochure"
    },
    emptyItem: {},
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "company",
            "label": "Compagnie"
        },
        {
            "name": "option",
            "label": "Formule"
        },
        {
            "name": "processed",
            "label": "Traité",
            formatter: Backoffice.formatters.boolean(['❌', '✅'])
        }
    ],
    fields: [
        {
            "name": "firstname",
            "label": "Prénom",
            readOnly: true
        },
        {
            "name": "lastname",
            "label": "Nom",
            readOnly: true

        },
        {
            "name": "email",
            "label": "Email",
            readOnly: true

        },
        {
            "name": "company",
            "label": "Compagnie",
            readOnly: true

        },
        {
            "name": "option",
            "label": "Formule",
            readOnly: true

        },
        {
            "name": "processed",
            "label": "Traité",
            formatter: Backoffice.formatters.boolean(['❌', '✅']),
            editor: {
                type: "boolean"
            }
        }
    ]
};
