import Backoffice from "@snark/backoffice";

const context = {
    company: Backoffice.populator("company", {
        displayProperty: item => {
            if (item.address && item.address.city) {
                return item.name + ' ' + item.address.city;
            } else {
                return item.name;
            }
        },
        nullable: true
    })
};

let crud = {
    name: "user",
    collection: "user",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Utilisateurs",
        "singleTitle": "Utilisateur"
    },
    emptyItem: {
        companies: [],
        role: "Manager"
    },
    listTextFilter: ['firstname', 'lastname', 'companyString'],
    sort: {
        back: [
            {field: "lastname"}
        ]
    },
    completeSingle: completeSingle,
    listColumns: [
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                "Collaborator": "Collaborateur",
                "Manager": "Manager"
            })
        },
        {
            "name": "companies",
            "label": "Transporteurs",
            formatter: Backoffice.formatters.tags(undefined, "", context.company.formatter())
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "operatorTermsSigned",
            "label": "Conditions acceptées",
            formatter: Backoffice.formatters.mapping({
                "true": "Oui",
                "undefined" : "Non",
                "null" : "Non",
                "false": "Non",
            })
        }
    ],
    loadSingle: loadSingle,
    loadList: loadList,
    filterList: filterList,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "role",
            "label": "Role",
            formatter: Backoffice.formatters.mapping({
                Collaborator: "Collaborateur",
                Manager: "Manager"
            }),
            editor: {
                "type": "select",
                "options": [
                    {
                        value: "Collaborator",
                        label: "Collaborateur"
                    },
                    {
                        value: "Manager",
                        label: "Manager"
                    }
                ]
            }
        },
        {
            "name": "username",
            "label": "Identifiant"
        },
        {
            "name": "newPassword",
            "label": "Nouveau mot de passe"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        },
        {
            "name": "companies",
            "label": "Transporteurs",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("company", {
                        label: context.company.formatter(),
                    }),
                    "editor": context.company.editor()
                }

            ]
        },
        {
            "name": "operatorTermsSigned",
            "label": "Conditions acceptées",
            "formatter": Backoffice.formatters.mapping({
                "true": "Oui",
                "undefined" : "Non",
                "null" : "Non",
                "false": "Non",
            }),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "lastMessageSeenAt",
            "label": "Dernier message vu le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL
            }
        }
    ]
};
export default crud;

function completeSingle(item) {
    if (item.companies === undefined) {
        item.companies = [];
    }
    return Promise.resolve(item);
}

function loadSingle() {
    return Promise.all([context.company.load()]);
}
function loadList() {
    return Promise.all([context.company.load()]);
}

async function filterList(items) {
    for(let i = 0, n = items.length; i < n; ++i) {
        let companyString = "";
        let separator = "";
        for(const companyId of items[i].companies) {
            const company = context.company.find(companyId);
            if(company) {
                companyString += separator;
                companyString += company.name;
                separator = ", ";
            }
            items[i].companyString = companyString;
        }
    }
    return items;
}