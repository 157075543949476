import Backoffice from "@snark/backoffice";

export default {
	name: "bouser",
	collection: "bouser",
	idProperty: "_id",
	labels: {
		"listTitle": "Utilisateurs Backoffice",
		"singleTitle": "Utilisateur Backoffice"
	},
	emptyItem: {
	},
	listColumns: [{
		"name": "firstname",
		"label": "Prénom"
	}, {
		"name": "lastname",
		"label": "Nom"
	}, {
		"name": "username",
		"label": "Username"
	}, {
		"name": "password",
		"label": "Mot de passe"
	}],
	fields: [{
		"name": "_id",
		"label": "Identifiant",
		"readOnly": true,
		"hideOnCreate": true
	}, {
		"name": "firstname",
		"label": "Prénom"
	}, {
		"name": "lastname",
		"label": "Nom"
	}, {
		"name": "username",
		"label": "Username"
	}, {
		"name": "password",
		"label": "Mot de passe",
		readOnly: true,
	}, {
		"name": "newPassword",
		"label": "Nouveau mot de passe",
	}, {
		"name": "createdAt",
		"label": "Créé le",
		"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
		"readOnly": true,
		"hideOnCreate": true
	}, {
		"name": "updatedAt",
		"label": "Mofifié le",
		"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
		"readOnly": true,
		"hideOnCreate": true
	}]
};
