import Backoffice from "@snark/backoffice";

const context = {
    companies: Backoffice.populator("company", {nullable: true}),
    travels: Backoffice.populator("travel", {nullable: true}),
    users: Backoffice.populator("user", {displayProperty: item => item.firstname + ' ' + item.lastname, nullable: true}),
    proposals: Backoffice.populator("proposal", {nullable: true}),
};

let crud = {
    name: "payment",
    collection: "payment",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Paiements",
        "singleTitle": "Paiment"
    },
    noCreate: true,
    readOnly: true,
    listColumns: [
        {
            name: "travelId",
            label: "Trajet",
            "formatter": Backoffice.formatters.linkObjectId('travel')
        },
        {
            "name": "companyId",
            "label": "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {"label": context.companies.formatter()})
        },
        {
            "name": "userId",
            "label": "Utilisateur",
            formatter: Backoffice.formatters.linkObjectId("user", {"label": context.users.formatter()})
        },
        {
            name: 'type',
            label: 'Type',
        },
        {
            name: 'state',
            label: 'Status'
        }

    ],
    loadList: loadList,
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant"
        },
        {
            name: "travelId",
            label: "Trajet",
            "formatter": Backoffice.formatters.linkObjectId('travel')
        },
        {
            "name": "companyId",
            "label": "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {"label": context.companies.formatter()})
        },
        {
            "name": "userId",
            "label": "Utilisateur",
            formatter: Backoffice.formatters.linkObjectId("user", {"label": context.users.formatter()})
        },
        {
            name: 'type',
            label: 'Type',
        },
        {
            "name": "state",
            "label": "État"
        },
        {
            "name": "amountHT",
            "label": "Prix HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "amountTTC",
            "label": "Prix TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "feeHT",
            "label": "Commission fullbus HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "feeTTC",
            "label": "Commission fullbus TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "mangoWireReference",
            "label": "Mango Référence",
        },
        {
            "name": "mangoBankAccount",
            "label": "Mango Bank Account",
        },
        {
            "name": "mangoPayinId",
            "label": "Mango PayIn ID",
        },
        {
            "name": "mangoPayinStatus",
            "label": "Mango PayIn Status",
        },
        {
            "name": "mangoPayIn",
            "label": "Mango PayIn",
        },
        {
            "name": "mangoTransferId",
            "label": "Mango Transfer ID",
        },
        {
            "name": "mangoTransferStatus",
            "label": "Mango Transfer Status",
        },
        {
            "name": "mangoTransfer",
            "label": "Mango Transfer",
        },
        {
            "name": "mangoPayoutId",
            "label": "Mango PayOut ID",
        },
        {
            "name": "mangoPayoutStatus",
            "label": "Mango PayOut Status",
        },
        {
            "name": "mangoPayout",
            "label": "Mango PayOut",
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ]
};

export default crud;

function loadList() {
    return Promise.all([context.users.load(),context.travels.load(), context.companies.load(), context.travels.load()]);
}

function loadSingle() {
    return Promise.all([context.users.load(), context.travels.load(), context.companies.load(), context.travels.load()]);
}