import Backoffice
	from "@snark/backoffice";
import {
	getSelectOptions
} from "@/helpers/helpers";
import ModalTestNotification
	from "@/components/ModalTestNotification.vue";

const NotificationType = {
	UserForgotPassword: "user_forgot_password",
	UserSentMessageIntoProposal: "user_sent_message_into_proposal",
	UserEvaluateTravelST: "user_evaluate_travel_st",
	UserEvaluateTravelDO: "user_evaluate_travel_do",
	UserProposalCancelled: "user_proposal_cancelled",
	UserProposalRefused: "user_proposal_refused",
	TravelCancelled: "travel_cancelled",
	ProposalBusUpdated: "proposal_bus_updated",
	DoProposeTravel: "do_propose_travel",
	UserProposalAccepted: "user_proposal_accepted",
	UserProposalUpdated: "user_proposal_updated",
	TravelValidated: "travel_validated",
	TravelPaid: "travel_paid",
	TravelToEvaluateST: "travel_to_evaluate_st",
	TravelToEvaluateDO: "travel_to_evaluate_do",
	UserPropose: "user_propose",
	AmendmentCancelled: "amendment-cancelled",
	StAddDriver: "st_add_driver",
	CompanyBusesFull: "company_buses_full",
	PaymentError: "payment_error",
	PayInSuccess: "payin_success",
	BrochureRequest: "brochure-request",
	NewTravel: "new-travel",
	QuestionBox: "question-box"
};

export default {
	name: "notification_model",
	collection: "notification_model",
	section: "Data",
	idProperty: "_id",
	labels: {
		"listTitle": "Modèles des notifications",
		"singleTitle": "Modèle de notification"
	},
	emptyItem: {
		templates: []
	},
	listColumns: [
		{
			"name": "type",
			"label": "Type"
		},
		{
			"name": "title",
			"label": "Titre",
		},
	],
	fields: [
		{
			"name": "_id",
			"label": "Identifiant",
			"readOnly": true,
			"hideOnCreate": true
		},
		{
			"name": "type",
			"label": "Type",
			formatter: Backoffice.formatters.mapping(getTypeMapping()),
			editor: {
				type: "select",
				options: getSelectOptions(NotificationType)
			}
		},
		{
			"name": "title",
			"label": "Titre",
		},
		{
			"name": "body",
			"label": "Corps",
			formatter: (field, item) => {
				console.log("format body: ", item);
				return `<div class="notificationHtml">${item.renderedBody}</div>`
			},
			editor: {
				type: "text",
				lines: 10
			}
		},
		{
			"name": "testingData",
			"label": "Données de test",
			formatter: (field, item) => {
				return `<pre class="code">${item.testingData}</pre>`
			},
			editor: {
				type: "text",
				lines: 10
			}
		},
		{
			"name": "createdAt",
			"label": "Créé le",
			"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
			"readOnly": true,
			"hideOnCreate": true
		},
		{
			"name": "updatedAt",
			"label": "Mofifié le",
			"formatter": Backoffice.formatters.date("DD/MM/YYYY"),
			"readOnly": true,
			"hideOnCreate": true
		}
	],
	viewActions: [
		{
			label: "Tester l'envoi",
			action: async function(notification) {
				return new Promise((resolve, _reject) => {
					this.appendComponent(ModalTestNotification, this, {
						notification,
						onTerminated: () => {
							resolve({reload: true});
						}
					});
				});
			}
		}
	]
};


function getTypeMapping() {
	let mapping = {};
	for(const key of Object.keys(NotificationType)) {
		mapping[NotificationType[key]] = key;
	}
	return mapping;
}