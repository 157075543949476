import Backoffice from "@snark/backoffice";
import axios from "axios";

const context = {
    companies: Backoffice.populator("company", {
        nullable: true
    }),
    users: Backoffice.populator("user", {
        displayProperty: item => {
            return item.firstname + ' ' + item.lastname;
        },
        nullable: true
    }),
    travels: Backoffice.populator("travel", {
        displayProperty: item => {
            return 'De ' + item.from.address.city + ' à ' + item.to.address.city;
        },
        nullable: true}),
    buses: Backoffice.populator("bus", {
        displayProperty: item => {
            return item.identifiant;
        },
        nullable: true
    })
};

let crud = {
    name: "proposal",
    collection: "proposal",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Propositions",
        "singleTitle": "Proposition"
    },
    noCreate: true,
    readOnly: true,
    listColumns: [
        {
            name: "travelDate",
            label: "Date du trajet",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "travelCompanyOwnerId",
            "label": "Transporteur DO",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "proposalCompanyOwnerId",
            "label": "Transporteur Sous-traitant",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "travelId",
            "label": "Trajet",
            formatter: Backoffice.formatters.linkObjectId("travel", {
                "label": context.travels.formatter()
            })
        },
        {
            "name": "state",
            "label": "État",
            formatter: Backoffice.formatters.mapping({
                "created": "Créée",
                "proposed": "Proposée",
                "accepted": "Acceptée",
                "refused": "Refusée",
                "closed": "Cloturée",
                "canceled": "Annulée"
            })
        }
    ],
    loadList: loadList,
    loadSingle: loadSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant"
        },
        {
            "name": "travelId",
            "label": "Trajet",
            formatter: Backoffice.formatters.linkObjectId("travel", {
                "label": context.travels.formatter()
            })
        },
        {
            name: "travelDate",
            label: "Date du trajet",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "travelCompanyOwnerId",
            "label": "Transporteur Donneur d'ordre",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "proposalCompanyOwnerId",
            "label": "Transporteur ST",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "stRead",
            "label": "Lue par le sous-traitant",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"])
        },
        {
            "name": "doRead",
            "label": "Lue par le donneur d'ordre",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"])
        },
        {
            "name": "discussion",
            "label": "Discussion",
            list: true,
            children: [
                {
                    name: "type",
                    label: "Type de message"
                },
                {
                    name: "message",
                    label: "Message"
                },
                {
                    name: "datetime",
                    label: "Date",
                    "formatter": Backoffice.formatters.date("DD/MM/YYYY")
                },
                {
                    condition: item => {
                        return ((item.type === "Text") || (item.type === "File"));
                    },
                    "name": "userId",
                    "label": "Responsable",
                    formatter: Backoffice.formatters.linkObjectId("user", {
                        "label": context.users.formatter()
                    })
                },
                {
                    condition: item => {
                        return ((item.type === "Text") || (item.type === "File"));
                    },
                    "name": "documents",
                    "label": "Documents",
                    children: [
                        {
                            name: "name",
                            image: "Nom du document",
                            readOnly: true,
                            hideOneCreate: true
                        },
                        {
                            name: "mimeType",
                            label: "Type de fichier",
                            readOnly: true,
                            hideOneCreate: true
                        },
                        {
                            name: "path",
                            label: "Fichier",
                            formatter: Backoffice.formatters.url({
                                urlPrefix: process.env.VUE_APP_CDN_URL,
                                maxHeight: 200
                            }),
                            readOnly: true,
                            hideOnCreate: true
                        }
                        ]
                }
            ]
        },
        {
            "name": "buses",
            "label": "Cars",
            list: true,
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("bus", {
                        "label": context.buses.formatter()
                    }),
                    editor: context.buses.editor()
                }
            ]
        },
        {
            "name": "state",
            "label": "État",
            formatter: Backoffice.formatters.mapping({
                "created": "Créée",
                "proposed": "Proposée",
                "accepted": "Acceptée",
                "refused": "Refusée",
                "closed": "Cloturée",
                "canceled": "Annulée"
            })
        },
        {
            "name": "priceHT",
            "label": "Prix Sous-traitant HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "priceTTC",
            "label": "Prix Sous-traitant TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "fullbusFeeHT",
            "label": "Commission fullbus HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "fullbusFeeTTC",
            "label": "Commission fullbus TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "totalHT",
            "label": "Total Demandeur HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "totalTTC",
            "label": "Total Demandeur TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ],
    viewActions: [
        {
            label: "\"Déaccepter\"",
            action: unacceptProposal
        }
    ]
};

export default crud;

function loadList() {
    return Promise.all([context.users.load(), context.companies.load(), context.travels.load(), context.buses.load()]);
}

function loadSingle() {
    return Promise.all([context.users.load(), context.companies.load(), context.travels.load(), context.buses.load()]);
}

export async function unacceptProposal(item) {
    try {
        await axios.post(process.env.VUE_APP_BASE_API_URL + "/bo/proposal/" + item._id + "/unaccept", {}, {
            headers: {
                'X-User-Token': localStorage.getItem("BACKOFFICE_TOKEN")
            }
        });
        return {
            reload: true,
            message: "La proposition est à nouveau en attente d'acceptation."
        };
    }
    catch(err) {
        console.log("Error unacceptProposal: ", err);
        throw "Problème. La proposition ou le trajet ne sont pas dans un bon état.";
    }
}