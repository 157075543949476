import Backoffice from "@snark/backoffice";

export default {
	name: "question",
	collection: "question",
	section: "Website",
	idProperty: "_id",
	noCreate: true,
	labels: {
		"listTitle": "Boite à questions",
		"singleTitle": "Question"
	},
	emptyItem: {
	},
	listColumns: [
		{
			"name": "firstname",
			"label": "Prénom"
		},
		{
			"name": "lastname",
			"label": "Nom"
		},
		{
			"name": "email",
			"label": "Email"
		},
		{
			"name": "company",
			"label": "Compagnie"
		},
		{
			"name": "message",
			"label": "Message"
		},
		{
			"name": "processed",
			"label": "Traité",
			formatter: Backoffice.formatters.boolean(['❌', '✅'])
		}
	],
	fields: [
		{
			"name": "firstname",
			"label": "Prénom",
			readOnly: true
		},
		{
			"name": "lastname",
			"label": "Nom",
			readOnly: true

		},
		{
			"name": "email",
			"label": "Email",
			readOnly: true
		},
		{
			"name": "company",
			"label": "Compagnie",
			readOnly: true

		},
		{
			"name": "message",
			"label": "Message",
			readOnly: true
		},
		{
			"name": "processed",
			"label": "Traité",
			formatter: Backoffice.formatters.boolean(['❌', '✅']),
			editor: {
				type: "boolean"
			}
		}
	]
};
