import FullBusRich from "../components/FullBusRich";

const typeOptions = [
    {
        label: "CGV-CGU",
        value: "cgv",
    },
    {
        label: "CGV-Booking",
        value: "cgvbooking",
    },
    {
        label: "Conditions de sous-traitance",
        value: "cgst",
    },
    {
        label: "Politique de confidentialité",
        value: "policy",
    },
]

let crud = {
    name: "legals",
    collection: "legals",
    section: "Website",
    idProperty: "_id",
    labels: {
        "listTitle": "Textes légaux",
        "singleTitle": "Texte légal"
    },
    emptyItem: {
        blocks: [],
    },
    listColumns: [
        {
            "name": "type",
            "label": "Type",
            formatter: (field, obj) => typeOptions.find((t) => t.value === obj.type).label,
        },
    ],
    fields: [
        {
            "name": "type",
            "label": "Type",
            formatter: (field, obj) => typeOptions.find((t) => t.value === obj.type).label,
            editor: {
                type: "select",
                options: typeOptions
            }
        },
        {
            name: "blocks",
            label: "Contenu",
            list: true,
            children: [
                {
                    label: 'Sections',
                    formatter: (field, obj) => {
                        if (obj.blockName === 'block-title') {
                            return "<div style='display: flex'><div style='min-width: 100px'>Titre</div><b style='font-size: 20px'><u>" + obj.text + "</u></b></div>";
                        }
                        if (obj.blockName === 'block-paragraph') {
                            return "<div style='display: flex'><div style='min-width: 100px'>Paragraphe</div><div>" + obj.text + "</div></div>";
                        }
                        if (obj.blockName === 'block-image') {
                            return "<div style='display: flex'><div style='min-width: 100px'>Image</div><img width='300' height='200' src='" + (obj.image && (process.env.VUE_APP_CDN_URL + obj.image.path) || '') + "' alt=''/></div>";
                        }
                    },
                    editor: {
                        type: 'custom',
                        component: FullBusRich
                    }
                }
            ]
        }
    ]
};
export default crud;

