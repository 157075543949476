import Vue from "vue";
import Backoffice from "@snark/backoffice";
import moment from "moment-timezone";
moment.tz.setDefault("Europe/Paris");
moment.locale("fr");

const context = {
    companies: Backoffice.populator("company", {
        nullable: true
    }),
    buses: Backoffice.populator("bus", {
        displayProperty: item => {
            return item.identifiant;
        },
        nullable: true
    }),
    active: 0
};

let crud = {
    name: "availability",
    collection: "availability",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Disponibilités",
        "singleTitle": "Disponibilité"
    },
    emptyItem: {
        type: "availability",
        address: {
            formatted_address: '',
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            country: '',
            countryCode: '',
            department: '',
            region: '',
            location: []
        },
        arrivalAddress: {
            formatted_address: '',
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            country: '',
            countryCode: '',
            department: '',
            region: '',
            location: []
        },
    },
    sort: {
        back:[
            {field: "start", order: "desc"}
        ]
    },
    listColumns: [
        {
            "name": "companyId",
            "label": "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "busId",
            "label": "Car",
            formatter: Backoffice.formatters.linkObjectId("bus", {
                "label": context.buses.formatter()
            })
        },
        {
            name: "start",
            label: "Date de début",
            formatter: Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            name: "end",
            label: "Date de fin",
            formatter: Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            name: "type",
            label: "Type",
            formatter: Backoffice.formatters.mapping({
                "availability": "Disponibilité",
                "emptyTravel": "Trajet à vide"
            })
        },
        {
            name: "address",
            label: "Lieu",
            formatter: function (field, item) {
                if (item.address && item.address.formatted_address) {
                    return item.address.formatted_address;
                } else {
                    return null;
                }
            }
        }
    ],
    loadList: loadList,
    filterList: filterList,
    loadSingle: loadSingle,
    completeSingle: completeSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            readOnly: true,
            hideOnCreate: true
        },
        {
            "name": "companyId",
            "label": "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            }),
            editor: context.companies.editor()
        },
        {
            "name": "busId",
            "label": "Car",
            formatter: Backoffice.formatters.linkObjectId("bus", {
                "label": context.buses.formatter()
            }),
            editor: {
                type: 'select',
                options: function (selectField) {
                    if (selectField && selectField.object && selectField.object.companyId) {
                        let company = context.companies.find(selectField.object.companyId)
                        let options = context.buses.getFilteredOptions(bus => {
                            if (bus.companyId.toString() === company._id.toString()) {
                                return bus;
                            }
                        });
                        return Promise.resolve(options);
                    } else {
                        return Promise.resolve([{label: '-', value: null}]);
                    }
                },
                watch: 'companyId'
            }
        },
        {
            name: "start",
            label: "Date de début",
            formatter: Backoffice.formatters.date("DD/MM/YYYY"),
            editor: {
                type: "date"
            }
        },
        {
            name: "end",
            label: "Date de fin",
            formatter: Backoffice.formatters.date("DD/MM/YYYY"),
            editor: {
                type: "date"
            }
        },
        {
            name: "type",
            label: "Type",
            formatter: Backoffice.formatters.mapping({
                "availability": "Disponibilité",
                "emptyTravel": "Trajet à vide"
            }),
            editor: {
                type: "select",
                options: [
                    {value: "availability", label: "Disponibilité"},
                    {value: "emptyTravel", label: "Trajet à vide"}
                ]
            }
        },
        {
            name: "address",
            label: "Adresse",
            children: [
                {
                    name: "formatted_address",
                    label: "Adresse complete"
                },
                {
                    name: "name",
                    label: "Nom"
                },
                {
                    name: "street",
                    label: "Rue"
                },
                {
                    name: "complement",
                    label: "Complément"
                },
                {
                    name: "city",
                    label: "Ville"
                },
                {
                    name: "citySlug",
                    label: "Ville formatée",
                    readOnly: true
                },
                {
                    name: "zip",
                    label: "Code postal"
                },
                {
                    name: "country",
                    label: "Pays"
                },
                {
                    name: "countryCode",
                    label: "Code pays",
                    readOnly: true
                },
                {
                    name: "department",
                    label: "Département",
                    readOnly: true
                },
                {
                    name: "region",
                    label: "Région",
                    readOnly: true
                },
                {
                    name: "location",
                    label: "Localisation",
                    list: true,
                    children: [
                        {
                            name: "_item",
                            noLabel: true
                        }
                    ],
                    readOnly: true
                },
            ],
            editor: {
                type: "address",
                completeField: 'formatted_address',
                gmapFields: null,
                finalTransformer: (value) => {
                    const newValue = {
                        ...value,
                        name: value.name,
                        street: value.street_number + ' ' + value.route,
                        zip: value.postal_code,
                        countryCode: value.country_code,
                        city: value.locality,
                        location: [value.longitude, value.latitude],
                    }
                    delete newValue.postal_code;
                    delete newValue.country_code;
                    delete newValue.locality;
                    delete newValue.longitude;
                    delete newValue.latitude;
                    delete newValue.street_number;
                    delete newValue.route;
                    return newValue;
                }
            }
        },
        {
            name: "arrivalAddress",
            label: "Adresse d'arrivée (pour les trajets à vide)",
            children: [
                {
                    name: "formatted_address",
                    label: "Adresse complete"
                },
                {
                    name: "name",
                    label: "Nom"
                },
                {
                    name: "street",
                    label: "Rue"
                },
                {
                    name: "complement",
                    label: "Complément"
                },
                {
                    name: "city",
                    label: "Ville"
                },
                {
                    name: "citySlug",
                    label: "Ville formatée",
                    readOnly: true
                },
                {
                    name: "zip",
                    label: "Code postal"
                },
                {
                    name: "country",
                    label: "Pays"
                },
                {
                    name: "countryCode",
                    label: "Code pays",
                    readOnly: true
                },
                {
                    name: "department",
                    label: "Département",
                    readOnly: true
                },
                {
                    name: "region",
                    label: "Région",
                    readOnly: true
                },
                {
                    name: "location",
                    label: "Localisation",
                    list: true,
                    children: [
                        {
                            name: "_item",
                            noLabel: true
                        }
                    ],
                    readOnly: true
                },
            ],
            editor: {
                type: "address",
                completeField: 'formatted_address',
                gmapFields: null,
                finalTransformer: (value) => {
                    const newValue = {
                        ...value,
                        name: value.name,
                        street: value.street_number + ' ' + value.route,
                        zip: value.postal_code,
                        countryCode: value.country_code,
                        city: value.locality,
                        location: [value.longitude, value.latitude],
                    }
                    delete newValue.postal_code;
                    delete newValue.country_code;
                    delete newValue.locality;
                    delete newValue.longitude;
                    delete newValue.latitude;
                    delete newValue.street_number;
                    delete newValue.route;
                    return newValue;
                }
            }
        },
        {
            name: "comment",
            label: "Commentaire/Précision",
            editor: {
                type: "text",
                lines: 10
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            readOnly: true,
            hideOnCreate: true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            readOnly: true,
            hideOnCreate: true
        }
    ],
    listHeader: Vue.component('FullBusInfoCard', {
        template: '<div class="Summary" style="margin-right: 30px;"><span>Eléments actifs: </span><strong>{{active}}</strong></div>',
        data() {
            return {active: "-"};
        },
        mounted() {
            setTimeout(() => {
                this.active = context.active;
            }, 500);
        },
    })
};

export default crud;

function loadList() {
    return Promise.all([context.companies.load(), context.buses.load()]);
}

function filterList(items) {
    let active = 0;
    let today = moment().startOf("day").valueOf();

    for(const item of items) {
        if(item.end >= today) {
            ++active;
        }
    }
    context.active = active;
    return items;
}

function loadSingle() {
    return Promise.all([context.companies.load(), context.buses.load()]);
}

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            formatted_address: '',
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            country: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }

    return Promise.resolve(item);
}
