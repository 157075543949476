import Backoffice from "@snark/backoffice";

const context = {
    section: Backoffice.populator("faq_section"),
};


let crud = {
    name: "faq",
    collection: "faq",
    section: "Website",
    idProperty: "_id",
    labels: {
        "listTitle": "FAQ",
        "singleTitle": "FAQ"
    },
    emptyItem: {
        sectionId: null,
        rank: 0
    },
    sort: {
        back: [
            {field: "sectionId"},
            {field: "rank"}
        ]
    },
    listColumns: [
        {
            "name": "sectionId",
            "label": "Section",
            formatter: context.section.formatter()
        },
        {
            "name": "question",
            "label": "Question"
        },
        {
            "name": "rank",
            "label": "Ordre"
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "sectionId",
            "label": "Section",
            formatter: Backoffice.formatters.linkObjectId('faq_section', {
                label: context.section.formatter(),
            }),
            editor: context.section.editor()
        },
        {
            "name": "question",
            "label": "Question"
        },
        {
            "name": "answer",
            "label": "Réponse",
            formatter: Backoffice.formatters.multiLines(),
            editor: {
                type: "text",
                lines: 5
            }
        },
        {
            "name": "rank",
            "label": "Ordre",
            editor: {
                type: "integer"
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    loadList: loadList,
    loadSingle: loadSingle
};

function loadList() {
    return Promise.all([
        context.section.load()
    ]);
}

function loadSingle() {
    return Promise.all([
        context.section.load()
    ]);
}

export default crud;
