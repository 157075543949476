import Backoffice from "@snark/backoffice";
import FullBusRich from "../components/FullBusRich";

const topicOptions = [
    {
        "label": "Nos villes passent la seconde",
        "value": "city_second"
    },
    {
        "label": "Les soutes des autocaristes",
        "value": "soutes"
    },
    {
        "label": "Partir à l'aventure",
        "value": "aventure"
    },
    {
        "label": "Initiatives full durables",
        "value": "init"
    }
];

const authorOptions = [
    {
        label: "Clémence CAMBY",
        value: "clemence",
        role: "Co-fondatrice Fullbus",
        "linkedin": "https://fr.linkedin.com/in/cl%C3%A9mence-camby",
    },
    {
        label: "Léa LASJAUNIAS",
        value: "lea",
        role: "Co-fondatrice Fullbus",
        "linkedin": "https://fr.linkedin.com/in/lea-lasjaunias",
    }
]

let crud = {
    name: "blog",
    collection: "blog",
    section: "Website",
    idProperty: "_id",
    labels: {
        "listTitle": "Articles du blog",
        "singleTitle": "Article"
    },
    emptyItem: {
        blocks: [],
        slug: '',
        topics: [],
        cover: {
            path: ''
        }
    },
    listTextFilter: ['title'],
    sort: {
        back: [
            {field: "createdAt"}
        ]
    },
    completeSingle: (obj) => {
        if (typeof obj.cover === "string")  {
            obj.cover = {path: obj.cover};
        }

        if (!obj.topics) {
            obj.topics = [];
        }

        return obj;
    },
    listColumns: [
        {
            "name": "title",
            "label": "Titre"
        },
        // {
        //     "name": "topicName",
        //     "label": "Topic"
        // },
        {
            "name": "createdAt",
            "label": "Date",
            formatter: Backoffice.formatters.date("DD/MM/YY")
        },
        // {
        //     "name": "author",
        //     "label": "Auteur",
        //     formatter: (field, obj) => obj.author && obj.author.fullName || '-'
        // }
    ],
    fields: [
        {
            name: "title",
            label: "Titre"
        },
        {
            name: "subtitle",
            label: "Sous-titre"
        },
        {
            name: "topics",
            list: true,
            children: [
                {
                    formatter: (field, obj) => obj.topicName,
                    listEmptyItem: {
                        topicName: '',
                        topicId: ''
                    },
                    label: "Topic",
                    name: "topicId",
                    editor: {
                        type: "select",
                        options: topicOptions
                    }
                }
            ]
        },
        {
            "name": "createdAt",
            "label": "Date",
            formatter: Backoffice.formatters.date("DD/MM/YY"),
            editor: {
                type: "date"
            }
        },
        {
            name: "duration",
            label: "Durée (en minutes)",
            editor: {
                type: "integer"
            }
        },
        {
            name: "cover",
            label: "Image de couverture",
            children: [
                {
                    name: 'path',
                    label: 'Image',
                    formatter: Backoffice.formatters.picture({
                        urlPrefix: process.env.VUE_APP_CDN_URL,
                        maxHeight: 200
                    }),
                    editor: {
                        type: 'image',
                        preview: {
                            width: 200,
                            height: 200,
                            urlPrefix: process.env.VUE_APP_CDN_URL,
                        },
                        accept: ['image/jpg','image/jpeg','image/png','image/gif'],
                    }
                }
            ]
        },
        {
            name: "author",
            label: "Auteur",
            formatter: (field, obj) => obj.author && obj.author.fullName || '-',
            editor: {
                type: "select",
                options: authorOptions
            }
        },
        {
            name: "blocks",
            label: "Contenu de l'article",
            list: true,
            children: [
                {
                    label: 'Sections',
                    formatter: (field, obj) => {
                        if (obj.blockName === 'block-title') {
                            return "<div style='display: flex'><div style='min-width: 100px'>Titre</div><b style='font-size: 20px'><u>" + obj.text + "</u></b></div>";
                        }
                        if (obj.blockName === 'block-paragraph') {
                            return "<div style='display: flex'><div style='min-width: 100px'>Paragraphe</div><div>" + obj.text + "</div></div>";
                        }
                        if (obj.blockName === 'block-image') {
                            return "<div style='display: flex'><div style='min-width: 100px'>Image</div><img width='300' height='200' src='" + (obj.image && (process.env.VUE_APP_CDN_URL + obj.image.path) || '') + "' /></div>";
                        }
                    },
                    editor: {
                        type: 'custom',
                        component: FullBusRich
                    }
                }
            ]
        }
    ]
};
export default crud;

