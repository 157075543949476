import Vue from "vue";
import Backoffice from "@snark/backoffice";

import crud_bouser from './crud/bouser';
import crud_user from './crud/user';
import crud_company from './crud/company';
import crud_depot from './crud/depot';
import crud_brand from './crud/brand';
import crud_model from './crud/model';
import crud_bus from './crud/bus';
import crud_travel from './crud/travel';
import crud_proposal from './crud/proposal';
import crud_payment from './crud/payment';
import crud_article from './crud/article';
import crud_legals from './crud/legals';
import crud_availability from './crud/availabilities';
import crud_brochure from './crud/brochure';
import crud_question from './crud/question';
import crud_testimony from "./crud/testimony";
import crud_partner from "./crud/partner";
import crud_press from "./crud/press";
import crud_faq from "./crud/faq";
import crud_faq_section from "./crud/faq-section";
import crud_faq_chapter from "./crud/faq-chapter";
import crud_system_message from "./crud/system_message";
import crud_notification_model from "./crud/notification_model";

Vue.config.productionTip = false;

Vue.use(Backoffice, {
    title: "Backoffice",
    apiUrl: process.env.VUE_APP_API_URL,
    gmapApiKey: process.env.VUE_APP_GMAP_KEY,
    logAs: {
        token: process.env.VUE_APP_LOGAS_TOKEN,
        frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL,
        apiRoute: process.env.VUE_APP_LOGAS_API_ROUTE
    },
    authenticate: function (login, password) {
        return Vue.backoffice.requestor
            .post("/bo/authenticate", "token", {
                username: login,
                password: password
            })
            .then(token => {
                return Promise.resolve({
                    token: token
                });
            });
    },
    crud: async function () {
        Vue.crud(crud_bouser);
        Vue.crud(crud_user);
        Vue.crud(crud_company);
        Vue.crud(crud_depot);
        Vue.crud(crud_bus);
        Vue.crud(crud_availability);
        Vue.crud(crud_brand);
        Vue.crud(crud_model);
        Vue.crud(crud_travel);
        Vue.crud(crud_payment);
        Vue.crud(crud_proposal);
        Vue.crud(crud_article);
        Vue.crud(crud_legals);
        Vue.crud(crud_brochure);
        Vue.crud(crud_question);
        Vue.crud(crud_testimony);
        Vue.crud(crud_partner);
        Vue.crud(crud_press);
        Vue.crud(crud_faq_chapter);
        Vue.crud(crud_faq_section);
        Vue.crud(crud_faq);
        Vue.crud(crud_system_message);
        Vue.crud(crud_notification_model);
    },
    defaultCRUDOptions: {
        editor: {
            select: {
                sort: 'asc'
            },
            checks: {
                sort: 'asc'
            }
        }
    },
    //routes: {
    //    prepare: function (routes) {
    //    }
    //},
    menuSections: ["backoffice", "App", "Website", "Data"],
    dashboardBlocks: []
});

Vue.backoffice.start(Vue);
