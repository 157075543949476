import Backoffice from "@snark/backoffice";

const typeOptions = [
    {
        label: "FAQ Principale",
        value: "main"
    },
    {
        label: "FAQ Client Booking",
        value: "client"
    }
];

let crud = {
    name: "faq_chapter",
    collection: "faq_chapter",
    section: "Website",
    idProperty: "_id",
    labels: {
        "listTitle": "Chapitres de la FAQ",
        "singleTitle": "Chapitre de la FAQ"
    },
    emptyItem: {
        rank: 0,
        type: "main"
    },
    sort: {
        back: [
            {field: "type"},
            {field: "rank"}
        ]
    },
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "type",
            "label": "Type",
            formatter: (field, obj) => typeOptions.find((t) => t.value === obj.type).label,
        },
        {
            "name": "rank",
            "label": "Ordre"
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "type",
            "label": "Type",
            formatter: (field, obj) => typeOptions.find((t) => t.value === obj.type).label,
            editor: {
                type: "select",
                options: typeOptions
            }
        },
        {
            "name": "rank",
            "label": "Ordre",
            editor: {
                type: "integer"
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};

export default crud;
