import Backoffice from "@snark/backoffice";

let crud = {
    name: "system_message",
    collection: "system_message",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Messages du Systeme",
        "singleTitle": "Message du Systeme"
    },
    emptyItem: {
        title: '',
        text: '',
    },
    sort: {
        back: [
            {field: "rank"},
            {field: "title"}
        ]
    },
    listColumns: [
        {
            "name": "title",
            "label": "Titre"
        },
        {
            "name": "text",
            "label": "Texte",
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "title",
            "label": "Titre"
        },
        {
            "name": "text",
            "label": "Texte",
            editor: {
                type: "text",
                lines: 10
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};

export default crud;
