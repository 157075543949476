import Backoffice from "@snark/backoffice";
import axios from 'axios';

let crud = {
    name: "company",
    collection: "company",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Transporteurs",
        "singleTitle": "Transporteur"
    },
    emptyItem: {
        managers: [],
        legalRepresentative: {
            firstName: '',
            lastName: '',
            birthday: 0,
            countryOfResidence: 'FR',// country code
            nationality: 'FR', // country code
            email: ''
        },
        invoiceNumber: 1,
        busMax: 9,
        alertEmails: []
    },
    noDelete: true,
    listTextFilter: ['name'],
    sort: {
        back: [
            {field: "name"}
        ]
    },
    listColumns: [
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "reunir",
            "label": "Adhérent Réunir",
            formatter: Backoffice.formatters.boolean(["-", "Oui"])
        }
    ],
    filterList: async function(items) {
        return items.filter(item => {
            console.log("item: ", item.name, item.state);
            return item.state !== "deleted";
        });
    },
    completeSingle: completeSingle,
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email (pour la réception des factures)"
        },
        {
            "name": "legalStatus",
            "label": "Statut légal"
        },
        {
            "name": "siret",
            "label": "SIREN/SIRET"
        },
        {
            "name": "reunir",
            "label": "Adhérent Réunir",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                type: "boolean",
                labels: ["Non", "Oui"]
            }
        },
        {
            name: "address",
            label: "Adresse",
            children: [
                {
                    name: "formatted_address",
                    label: "Adresse complete"
                },
                {
                    name: "name",
                    label: "Nom"
                },
                {
                    name: "street",
                    label: "Rue"
                },
                {
                    name: "complement",
                    label: "Complément"
                },
                {
                    name: "city",
                    label: "Ville"
                },
                {
                    name: "citySlug",
                    label: "Ville formatée",
                    readOnly: true
                },
                {
                    name: "zip",
                    label: "Code postal"
                },
                {
                    name: "country",
                    label: "Pays"
                },
                {
                    name: "countryCode",
                    label: "Code pays",
                    readOnly: true
                },
                {
                    name: "department",
                    label: "Département",
                    readOnly: true
                },
                {
                    name: "region",
                    label: "Région",
                    readOnly: true
                },
                {
                    name: "location",
                    label: "Localisation",
                    list: true,
                    children: [
                        {
                            name: "_item",
                            noLabel: true
                        }
                    ],
                    readOnly: true
                },
            ],
            editor: {
                type: "address",
                completeField: 'formatted_address',
                gmapFields: null,
                finalTransformer: (value) => {
                    const newValue = {
                        ...value,
                        name: value.name,
                        street: value.street_number + ' ' + value.route,
                        zip: value.postal_code,
                        countryCode: value.country_code,
                        city: value.locality,
                        location: [value.longitude, value.latitude],
                    }
                    delete newValue.postal_code;
                    delete newValue.country_code;
                    delete newValue.locality;
                    delete newValue.longitude;
                    delete newValue.latitude;
                    delete newValue.street_number;
                    delete newValue.route;
                    return newValue;
                }
            }
        },
        {
            name: "alertDepartments",
            label: "Départements d'alerte",
            list: true,
            emptyItem: ""
        },
        {
            "name": "legalRepresentative",
            "label": "Représentant Légal",
            children: [
                {
                    "name": "firstName",
                    "label": "Prénom"
                },
                {
                    "name": "lastName",
                    "label": "Nom"
                },
                {
                    "name": "email",
                    "label": "Email"
                },
                {
                    "name": "birthday",
                    "label": "Date de Naissance",
                    "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
                    editor: {
                        type: "date"
                    }
                },
                {
                    "name": "countryOfResidence",
                    "label": "Pays de Naissance",
                    editor: {
                        type: 'select',
                        countryOptions: true
                    }
                },
                {
                    "name": "nationality",
                    "label": "Nationalité",
                    editor: {
                        type: 'select',
                        countryOptions: true
                    }
                }
            ]
        },
        {
            "name": "managers",
            "label": "Responsables",
            list: true,
            children: [
                {
                    "name": "firstname",
                    "label": "Prénom"
                },
                {
                    "name": "lastname",
                    "label": "Nom"
                },
                {
                    "name": "role",
                    "label": "Fonction"
                },
                {
                    "name": "email",
                    "label": "Email"
                },
                {
                    "name": "phone",
                    "label": "Téléphone"
                },
                {
                    "name": "cellPhone",
                    "label": "Téléphone Portable"
                }
            ]
        },
        {
            name: "alertEmails",
            label: "Emails utilisé pour les alertes",
            list: true,
            children: [
                {
                    name: "_item"
                }
            ]
        },
        {
            "name": "totalBus",
            "label": "Flotte totale",
            "editor": {
                type: "integer"
            }
        },
        {
            "name": "totalTourism",
            "label": "Véhicules de tourisme",
            "editor": {
                type: "integer"
            }
        },
        {
            "name": "mangoPayUserId",
            "label": "Identifiant Utilisateur MangoPay",
            //"readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "mangoPayWalletId",
            "label": "Identifiant Portefeuille MangoPay",
            //"readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "mangoBankAccountId",
            "label": "Identifiant compte bancaire Mango",
            //"readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "mangoKycStatus",
            "label": "Statut KYC Mango",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "mangoKycTimestamp",
            "label": "Date KYC Mango",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "logo",
            "label": "Logo",
            formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({
                urlPrefix: process.env.VUE_APP_CDN_URL,
                maxHeight: 200
            })),
            editor: {
                type: 'image',
                preview: {
                    width: 340,
                    height: 256,
                    formatUrl: Backoffice.formatters.subProperty('path'),
                    urlPrefix: process.env.VUE_APP_CDN_URL
                },
                treatment: {
                    width: 1024,
                    height: 768,
                    type: 'resize-cover',
                    format: 'image/jpg',
                    quality: 10
                }
            }
        },
        {
            "name": "invoiceNumber",
            "label": "Numéro de facture",
            "readOnly": true
        },
        {
            "name": "busMax",
            "label": "Nombre de cars maximum",
            editor: {
                type: "integer"
            }
        },
        {
            "name": "disabled",
            "label": "Désactivé",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
            editor: {
                "type": "boolean",
                "labels": ["Non", "Oui"]
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                field: '_id',
                frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL
            }
        },
        {
            label: 'Créer user (mango)',
            check: item => !item.mangoPayUserId,
            action: item => {
                return axios.post(process.env.VUE_APP_API_URL + "/bo/company/createMangoUser", {companyId: item._id})
                    .then(res => {
                        if (res.data && res.data.success) {
                            return {reload: true, message: 'Utilisateur créé sur mango'}
                        }
                        throw new Error('Impossible de créér l\'utilisateur sur mango');
                    })
            }
        },
        {
            label: 'Créer wallet (mango)',
            check: item => item.mangoPayUserId && !item.mangoPayWalletId,
            action: item => {
                return axios.post(process.env.VUE_APP_API_URL + "/bo/company/createMangoWallet", {companyId: item._id})
                    .then(res => {
                        if (res.data && res.data.success) {
                            return {reload: true, message: 'Wallet créé sur mango'}
                        }
                        throw new Error('Impossible de créér le wallet sur mango');
                    })
            }
        }
    ],
};

export default crud;

function completeSingle(item) {
    if (item.legalRepresentative === undefined) {
        item.legalRepresentative = {
            firstName: '',
            lastName: '',
            birthday: 0,
            countryOfResidence: '',// country code
            nationality: '', // country code
            email: ''
        }
    }
    if (item.managers === undefined) {
        item.managers = [];
    }
    if (item.invoiceNumber === undefined) {
        item.invoiceNumber = 1;
    }
    if(item.busMax === undefined) {
        item.busMax = 9;
    }
    if (item.alertDepartments === undefined) {
        item.alertDepartments = [];
    }
    if (item.alertEmails === undefined) {
        item.alertEmails = [];
    }

    return Promise.resolve(item);
}