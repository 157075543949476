import Backoffice from "@snark/backoffice";

const context = {
    company: Backoffice.populator("company", {nullable: true})
};

export default {
    name: "depot",
    collection: "depot",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Depots",
        "singleTitle": "Depot"
    },
    noCreate: true,
    readOnly: true,
    emptyItem: {
        address: {
            formatted_address: '',
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            country: '',
            countryCode: '',
            department: '',
            region: '',
            location: []
        },
    },
    listTextFilter: ['name'],
    sort: {
        back: [
            {field: "name"}
        ]
    },
    completeSingle: completeSingle,
    loadList: loadList,
    loadSingle: loadSingle,
    listColumns: [
        {
            name: "name",
            label: "Nom"
        },
        {
            name: "address",
            label: "Adresse",
            formatter: Backoffice.formatters.subProperty("formatted_address")
        },
        {
            "name": "companyId",
            "label": "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                label: context.company.formatter(),
            }),
        }
    ],
    fields: [
        {
            name: "_id",
            label: "Identifiant",
            readOnly: true,
            hideOnCreate: true
        },
        {
            name: "name",
            label: "Nom",
            placeholder: "ex: Depot principal"
        },
        {
            name: "address",
            label: "Adresse",
            children: [
                {
                    name: "formatted_address",
                    label: "Adresse complete"
                },
                {
                    name: "name",
                    label: "Nom"
                },
                {
                    name: "street",
                    label: "Rue"
                },
                {
                    name: "complement",
                    label: "Complément"
                },
                {
                    name: "city",
                    label: "Ville"
                },
                {
                    name: "citySlug",
                    label: "Ville formatée",
                    readOnly: true
                },
                {
                    name: "zip",
                    label: "Code postal"
                },
                {
                    name: "country",
                    label: "Pays"
                },
                {
                    name: "countryCode",
                    label: "Code pays",
                    readOnly: true
                },
                {
                    name: "department",
                    label: "Département",
                    readOnly: true
                },
                {
                    name: "region",
                    label: "Région",
                    readOnly: true
                },
                {
                    name: "location",
                    label: "Localisation",
                    list: true,
                    children: [
                        {
                            name: "_item",
                            noLabel: true
                        }
                    ],
                    readOnly: true
                },
            ],
            editor: {
                type: "address",
                completeField: 'formatted_address',
                gmapFields: null,
                finalTransformer: (value) => {
                    const newValue = {
                        ...value,
                        name: value.name,
                        street: value.street_number + ' ' + value.route,
                        zip: value.postal_code,
                        countryCode: value.country_code,
                        city: value.locality,
                        location: [value.longitude, value.latitude],
                    }
                    delete newValue.postal_code;
                    delete newValue.country_code;
                    delete newValue.locality;
                    delete newValue.longitude;
                    delete newValue.latitude;
                    delete newValue.street_number;
                    delete newValue.route;
                    return newValue;
                }
            }
        },
        {
            name: "companyId",
            label: "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                label: context.company.formatter(),
            }),
            editor: context.company.editor()
        }
    ],
};

function loadList() {
    return Promise.all([context.company.load()]);
}

function loadSingle() {
    return Promise.all([context.company.load()]);
}

function completeSingle(item) {
    if (item.address === undefined) {
        item.address = {
            formatted_address: '',
            name: '',
            street: '',
            complement: '',
            city: '',
            citySlug: '',
            zip: '',
            country: '',
            countryCode: 'FR',
            department: '',
            region: '',
            location: []
        }
    }

    return Promise.resolve(item);
}
