import Backoffice from "@snark/backoffice";
import Vue from "vue";
import axios from "axios";
const context = {
    depot: Backoffice.populator("depot", {nullable: true}),
    brand: Backoffice.populator("brand", {nullable: true}),
    model: Backoffice.populator("model", {nullable: true}),
    company: Backoffice.populator("company", {nullable: true})
};

export default {
    name: "bus",
    collection: "bus",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Véhicules",
        "singleTitle": "Véhicule"
    },
    noCreate: true,
    api: {
        list(skip, limit, sort) {
            return Vue.backoffice.crudRequestor.list("bus", skip, limit, sort, {state: {$ne: "deleted"}});
        },
        delete(itemId) {
            return axios.post(process.env.VUE_APP_API_URL + "/bo/bus", {itemId: itemId});
        }
    },
    emptyItem: {
        companyId: null,
        brandId: null
    },
    listTextFilter: ['identifiant'],
    sort: {
        back: [
            {field: "identifiant"}
        ]
    },
    listColumns: [
        {
            name: "identifiant",
            label: "Identifiant"
        },
        {
            name: "brandId",
            label: "Marque",
            formatter: Backoffice.formatters.linkObjectId("brand", {
                label: context.brand.formatter(),
            })
        },
        {
            name: "modelId",
            label: "Model",
            formatter: Backoffice.formatters.linkObjectId("model", {
                label: context.model.formatter(),
            })
        },
        {
            name: "companyId",
            label: "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                label: context.company.formatter(),
            })
        },
        {
            name: "depotId",
            label: "Depot",
            formatter: Backoffice.formatters.linkObjectId("depot", {
                label: context.depot.formatter(),
            })
        }
    ],
    loadList: loadList,
    loadSingle: loadSingle,
    fields: [
        {
            name: "_id",
            label: "Identifiant",
            readOnly: true,
            hideOnCreate: true
        },
        {
            name: "identifiant",
            label: "Identifiant (texte)"
        },
        {
            name: "brandId",
            label: "Marque",
            formatter: Backoffice.formatters.linkObjectId("brand", {
                label: context.brand.formatter(),
            }),
            editor: context.brand.editor()
        },
        {
            name: "modelId",
            label: "Model",
            formatter: Backoffice.formatters.linkObjectId("model", {
                label: context.model.formatter(),
            }),
            editor: {
                type: 'select',
                options: selectModelsFromBrand
            }
        },
        {
            name: "companyId",
            label: "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                label: context.company.formatter(),
            }),
            editor: context.company.editor()
        },
        {
            name: "depotId",
            label: "Depot",
            formatter: Backoffice.formatters.linkObjectId("depot", {
                label: context.depot.formatter(),
            }),
            editor: {
                type: 'select',
                options: selectDepotsFromCompany
            }
        },
        {
            name: "year",
            label: "Annee"
        },
        {
            name: "capacity",
            label: "Capacite"
        },
        {
            name: "equipments",
            label: "Equipements",
            children: [
                {
                    name: "wifi",
                    label: "Wifi",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "electricalOutlet",
                    label: "Prises électriques",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "usbOutlet",
                    label: "Prises USB",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "ac",
                    label: "Climatisation",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "toilet",
                    label: "Toilettes",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "video",
                    label: "Vidéo",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "recliningSeat",
                    label: "Sièges inclinables",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "coffeeMachine",
                    label: "Machine à café",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "fridge",
                    label: "Réfrigérateur",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "table",
                    label: "Table",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "equipmentPMR",
                    label: "Équipements PMR",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "critAir",
                    label: "CRIT AIR",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "guideSeat",
                    label: "Siège du guide",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "micro",
                    label: "Micro",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                }
            ]
        },
        {
            name: "pictures",
            label: "Images",
            list: true,
            children: [
                {
                    name: "name",
                    image: "Nom de l'image"
                },
                {
                    name: "mimeType",
                    label: "Type de fichier"
                },
                {
                    name: "path",
                    label: "Image",
                    formatter: Backoffice.formatters.picture({
                        urlPrefix: process.env.VUE_APP_CDN_URL,
                        maxHeight: 200
                    })
                }
            ]
        },
        {
            name: "documents",
            label: "Documents",
            children: [
                {
                    name: "name",
                    image: "Nom du document"
                },
                {
                    name: "mimeType",
                    label: "Type de fichier"
                },
                {
                    name: "path",
                    label: "Fichier",
                    formatter: Backoffice.formatters.url({
                        urlPrefix: process.env.VUE_APP_CDN_URL,
                        maxHeight: 200
                    })
                }
            ]
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};

function loadList() {
    return Promise.all([
        context.depot.load(),
        context.brand.load(),
        context.model.load(),
        context.company.load()
    ]);
}

function loadSingle() {
    return Promise.all([
        context.depot.load(),
        context.brand.load(),
        context.model.load(),
        context.company.load()
    ]);
}

function selectDepotsFromCompany(selectField) {
    const result = [];

    if (selectField && selectField.object && selectField.object.companyId) {
        const selectedCompanyId = selectField.object.companyId.toString();

        for (const depot of context.depot.items) {
            if (depot.companyId.toString() === selectedCompanyId) {
                result.push({
                    label: depot.name,
                    value: depot._id
                });
            }
        }

        if (result.length === 0) {
            result.push({label: '-', value: null});
        }
    } else {
        result.push({label: '-', value: null});
    }

    return Promise.resolve(result);
}

function selectModelsFromBrand(selectField) {
    const result = [];

    if (selectField && selectField.object && selectField.object.brandId) {
        const selectedBrandId = selectField.object.brandId.toString();

        for (const model of context.model.items) {
            if (model.brandId.toString() === selectedBrandId) {
                result.push({
                    label: model.name,
                    value: model._id
                });
            }
        }

        if (result.length === 0) {
            result.push({label: '-', value: null});
        }
    } else {
        result.push({label: '-', value: null});
    }

    return Promise.resolve(result);
}
