import Backoffice from "@snark/backoffice";
import axios from "axios";
import moment from "moment-timezone";
import Vue from "vue";
moment.tz.setDefault("Europe/Paris");
moment.locale("fr");

const context = {
    companies: Backoffice.populator("company", {nullable: true}),
    users: Backoffice.populator("user", {displayProperty: item => item.firstname + ' ' + item.lastname, nullable: true}),
    proposals: Backoffice.populator("proposal", {nullable: true}),
    payments: Backoffice.populator("payment", {nullable: true})
};

let crud = {
    name: "travel",
    collection: "travel",
    section: "App",
    idProperty: "_id",
    labels: {
        "listTitle": "Trajets",
        "singleTitle": "Trajet"
    },
    noCreate: true,
    readOnly: true,
    sort: {
        back:[
            {field: "from.datetime", order: "desc"}
        ]
    },
    listTextFilter: [
        "companyName",
        "from.address.city",
        "to.address.city",
        "fromBack.address.city",
        "toBack.address.city",
        "information",
        "proposalCompanyName",
        "state",
        "bookingOrderId"
    ],
    listColumns: [
        {
            "name": "bookingOrderId",
            "label": "Booking",
            formatter: function(field, item) {
                if(item[field.name]) {
                    return "Oui";
                }
                else {
                    return "";
                }
            }
        },
        {
            "name": "companyId",
            "label": "Transporteur DO",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "proposalCompanyId",
            "label": "Transporteur ST",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "state",
            label: "Status"
        },
        {
            "name": "from",
            label: "Depuis",
            formatter: Backoffice.formatters.subProperty("address.formatted_address")
        },
        {
            name: "from",
            label: "Le",
            formatter: Backoffice.formatters.subProperty("datetime", Backoffice.formatters.date("DD/MM/YYYY"))
        },
        {
            "name": "to",
            label: "Vers",
            formatter: Backoffice.formatters.subProperty("address.formatted_address")
        },
        {
            name: "to",
            label: "Le",
            formatter: Backoffice.formatters.subProperty("datetime", Backoffice.formatters.date("DD/MM/YYYY"))
        },
    ],
    loadList: loadList,
    loadSingle: loadSingle,
    completeSingle: context.payments.completeSingle("paymentId", "payment"),
    fields: [
        {
            "name": "_id",
            "label": "Identifiant"
        },
        {
            "name": "bookingOrderId",
            "label": "Booking Id"
        },
        {
            "name": "userId",
            "label": "Responsable",
            formatter: Backoffice.formatters.linkObjectId("user", {
                "label": context.users.formatter()
            })
        },
        {
            "name": "companyId",
            "label": "Transporteur",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            name: "from",
            label: "De",
            children: [
                {
                    name: "datetime",
                    label: "Date",
                    "formatter": Backoffice.formatters.date("DD/MM/YYYY HH:mm")
                },
                {
                    name: "address",
                    label: "Adresse",
                    formatter: Backoffice.formatters.address({completeField: 'formatted_address'})
                }
            ]
        },
        {
            name: "to",
            label: "À",
            children: [
                {
                    name: "datetime",
                    label: "Date",
                    "formatter": Backoffice.formatters.date("DD/MM/YYYY HH:mm")
                },
                {
                    name: "address",
                    label: "Adresse",
                    formatter: Backoffice.formatters.address({completeField: 'formatted_address'})
                }
            ]
        },
        {
            name: "fromBack",
            label: "Retour de",
            children: [
                {
                    name: "datetime",
                    label: "Date",
                    "formatter": Backoffice.formatters.date("DD/MM/YYYY HH:mm")
                },
                {
                    name: "address",
                    label: "Adresse",
                    formatter: Backoffice.formatters.address({completeField: 'formatted_address'})
                }
            ]
        },
        {
            name: "toBack",
            label: "Retour à",
            children: [
                {
                    name: "datetime",
                    label: "Date",
                    "formatter": Backoffice.formatters.date("DD/MM/YYYY HH:mm")
                },
                {
                    name: "address",
                    label: "Adresse",
                    formatter: Backoffice.formatters.address({completeField: 'formatted_address'})
                }
            ]
        },
        {
            "name": "capacity",
            "label": "Capacité"
        },
        {
            "name": "equipments",
            "label": "Équipements",
            children: [
                {
                    name: "wifi",
                    label: "Wifi",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "electricalOutlet",
                    label: "Prises électriques",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "usbOutlet",
                    label: "Prises USB",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "ac",
                    label: "Climatisation",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "toilet",
                    label: "Toilettes",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "video",
                    label: "Vidéo",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "recliningSeat",
                    label: "Sièges inclinables",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "coffeeMachine",
                    label: "Machine à café",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "fridge",
                    label: "Réfrigérateur",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "table",
                    label: "Table",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "equipmentPMR",
                    label: "Équipements PMR",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "critAir",
                    label: "CRIT AIR",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "guideSeat",
                    label: "Siège du guide",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                },
                {
                    name: "micro",
                    label: "Micro",
                    formatter: Backoffice.formatters.boolean(['Non', 'Oui']),
                    editor: {
                        type: "boolean",
                        labels: ["Non", "Oui"]
                    }
                }
            ]
        },
        {
            "name": "initiallyEmpty",
            "label": "Initialement vide",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"])
        },
        {
            "name": "distance",
            "label": "Distance"
        },
        {
            "name": "customerType",
            "label": "Typologie de client",
            formatter: Backoffice.formatters.mapping({
                "Association": "Association",
                "Company-CE": "Entreprise/CE",
                "Sport-Club": "Club sportif",
                "Senior-Group": "Groupe sénior",
                "Junior-Group": "Groupe junior",
                "Other": "Autre"
            })
        },
        {
            name: "travelType",
            label: "Gamme de véhicule",
            formatter: Backoffice.formatters.mapping({
                Mixt: "Mixte",
                Tourism: "Tourisme",
                GrandTourism: "Grand-Tourisme",
                Vip: "VIP"
            })
        },
        {
            name: "travelPurpose",
            label: "Motif du trajet",
            formatter: Backoffice.formatters.mapping({
                "event": "Événement sportif",
                "enterprise": "Entreprise (CE, séminaire, congrès)",
                "students": "Groupe étudiants",
                "seniors": "Groupe séniors",
                "recreation": "Loisirs et culture",
                "private": "Événement privé (mariage, cérémonie, ...)",
                "other": "Autre"
            })
        },
        {
            "name": "information",
            "label": "Information"
        },
        {
            "name": "luggageType",
            "label": "Type de bagages"
        },
        {
            "name": "specificConditions",
            "label": "Conditions spécifiques de dérogation aux CGV"
        },
        {
            name: "documents",
            label: "Documents",
            children: [
                {
                    name: "name",
                    image: "Nom du document"
                },
                {
                    name: "mimeType",
                    label: "Type de fichier"
                },
                {
                    name: "path",
                    label: "Fichier",
                    formatter: Backoffice.formatters.url({
                        urlPrefix: process.env.VUE_APP_CDN_URL,
                        maxHeight: 200
                    })
                }
            ]
        },
        {
            "name": "proposalId",
            "label": "Proposition",
            formatter: Backoffice.formatters.linkObjectId("proposal")
        },
        {
            "name": "proposalCompanyId",
            "label": "Tansporteur sous-traitant",
            formatter: Backoffice.formatters.linkObjectId("company", {
                "label": context.companies.formatter()
            })
        },
        {
            "name": "paymentId",
            "label": "Paiement",
            formatter: Backoffice.formatters.linkObjectId("payment")
        },
        {
            "name": "amendments",
            "label": "Avenants",
            children: [
                {
                    "name": "_item",
                    "noLabel": true,
                    formatter: Backoffice.formatters.linkObjectId("payment", {
                        "label": context.payments.formatter()
                    }),
                }
            ]
        },
        {
            name: "evaluations",
            label: "Évaluations",
            children: [
                {
                    name: "DO",
                    label: "Donneur d'ordres",
                    children: [
                        {
                            "name": "userId",
                            "label": "Collaborateur",
                            formatter: Backoffice.formatters.linkObjectId("user", {
                                "label": context.users.formatter()
                            })
                        },
                        {
                            name: "score",
                            label: "Note"
                        },
                        {
                            name: "comment",
                            label: "Commentaire"
                        }
                    ]
                },
                {
                    name: "ST",
                    label: "Sous-traitant",
                    children: [
                        {
                            "name": "userId",
                            "label": "Collaborateur",
                            formatter: Backoffice.formatters.linkObjectId("user", {
                                "label": context.users.formatter()
                            })
                        },
                        {
                            name: "score",
                            label: "Note"
                        },
                        {
                            name: "comment",
                            label: "Commentaire"
                        }
                    ]
                }
            ]
        },
        {
            "name": "state",
            "label": "État"
        },
        {
            "name": "previousState",
            "label": "État antérieur"
        },
        {
            "name": "priceDesiredHT",
            "label": "Prix souhaité",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "priceHT",
            "label": "Prix Sous-traitant HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "priceTTC",
            "label": "Prix Sous-traitant TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "fullbusFeeHT",
            "label": "Commission fullbus HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "fullbusFeeTTC",
            "label": "Commission fullbus TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "totalHT",
            "label": "Total Demandeur HT",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "totalTTC",
            "label": "Total Demandeur TTC",
            formatter: Backoffice.formatters.price()
        },
        {
            "name": "roundTrip",
            "label": "Aller-retour",
            formatter: Backoffice.formatters.boolean(["Non", "Oui"])

        },
        {
            name: "driver",
            label: "Conducteur",
            children: [
                {
                    name: "firstname",
                    label: "Prénom"
                },
                {
                    name: "lastname",
                    label: "Nom"
                },
                {
                    name: "phone",
                    label: "Téléphone"
                }
            ]
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ],
    viewActions: [
        {
            label: 'Payer le ST',
            check: item => {
                return item.state === 'validated' || item.state === 'errorTransfer' && (!item.payment.mangoTransferStatus || item.payment.mangoTransferStatus === "TRANSFER_NORMAL_FAILED")
            },
            action: item => {
                return axios.post(process.env.VUE_APP_API_URL + "/bo/travel/transfer", {travelId: item._id})
                    .then(res => {
                        if (res.data && res.data.success) {
                            return {reload: true, message: 'Transfer effectué'}
                        }
                        throw new Error('Impossible d\'effectuer le transfer');
                    })
            }
        },
        {
            label: 'Virement vers le ST',
            check: item => {
                return item.state === 'terminated' || item.state === 'errorPayOut' && (!item.payment.mangoPayoutStatus || item.payment.mangoPayoutStatus === "PAYOUT_NORMAL_FAILED")
            },
            action: item => {
                return axios.post(process.env.VUE_APP_API_URL + "/bo/travel/payout", {travelId: item._id})
                    .then(res => {
                        if (res.data && res.data.success) {
                            return {reload: true, message: 'Virment effectué'}
                        }
                        throw new Error('Impossible d\'effectuer le virement');
                    })
            }
        },
    ],
    filterList,
    listHeader: Vue.component('FullBusInfoCard', {
        template: '<div class="Summary" style="margin-right: 30px;"><span>Eléments actifs: </span><strong>{{active}}</strong></div>',
        data() {
            return {active: "-"};
        },
        mounted() {
            setTimeout(() => {
                this.active = context.active;
            }, 500);
        },
    })
};

export default crud;

function loadList() {
    return Promise.all([context.users.load(), context.companies.load(), context.proposals.load(), context.payments.load()]);
}

function loadSingle() {
    return Promise.all([context.users.load(), context.companies.load(), context.proposals.load(), context.payments.load()]);
}

const ActiveStates = [
  "created",
  "discussion",
  "accepted",
  "paid",
  "validated",
  "terminated",
  "evaluate",
  "amendmentPresented", // avenant présenté
  "amendmentPresentedAfterPayment", // avenant présenté après paiement
  "amendmentValidated", // avenant validé
  "disputed", // en litige
  'errorPayIn',
  'errorTransfer',
  'errorPayOut'
];

function filterList(items) {
    let active = 0;

    for(const item of items) {
        if(ActiveStates.includes(item.state)) {
            ++active;
        }
    }
    console.log("ACTIVE = " + active);
    context.active = active;
    return items;
}
