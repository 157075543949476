import Backoffice from "@snark/backoffice";

const context = {
    chapter: Backoffice.populator("faq_chapter"),
};

let crud = {
    name: "faq_section",
    collection: "faq_section",
    section: "Website",
    idProperty: "_id",
    labels: {
        "listTitle": "Sections de la FAQ",
        "singleTitle": "Section de la FAQ"
    },
    emptyItem: {
        rank: 0,
        chapterId: null
    },
    sort: {
        back: [
            {field: "chapterId"},
            {field: "rank"},
        ]
    },
    listColumns: [
        {
            "name": "chapterId",
            "label": "Chapitre",
            formatter: context.chapter.formatter()

        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "rank",
            "label": "Ordre"
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "chapterId",
            "label": "Chapitre",
            formatter: Backoffice.formatters.linkObjectId("faq_chapter", {
                label: context.chapter.formatter(),
            }),
            editor: context.chapter.editor()
        },
        {
            "name": "name",
            "label": "Nom"
        },
        {
            "name": "rank",
            "label": "Ordre",
            editor: {
                type: "integer"
            }
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Mofifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    loadList: loadList,
    loadSingle: loadSingle
};

function loadList() {
    return Promise.all([
        context.chapter.load()
    ]);
}

function loadSingle() {
    return Promise.all([
        context.chapter.load()
    ]);
}


export default crud;
