<script>
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Paris');
moment.locale('fr');
import axios from "axios";

export default {
    name: 'modal-test-notification',
    props: {
		notification: Object,
        onTerminated: Function
    },

    data () {
        return {
            error: null,
            email: process.env.VUE_APP_TEST_NOTIFICATION_EMAIL
        }
    },

    computed: {
        buttons() {
            return [
                {
                    label: "Envoyer le test",
                    action: this.sendNotification
                }
            ];
        }
    },

    methods: {
        async sendNotification() {
            this.error = null;
            try {
                await axios.post(process.env.VUE_APP_API_URL + "/bo/notify", {
					type: this.notification.type,
					data: JSON.parse(this.notification.testingData),
					_testingEmail: this.email
				}, {
					headers: {
						'X-User-Token': localStorage.getItem("BACKOFFICE_TOKEN")
					}
				});
				if (this.onTerminated) {
					this.onTerminated();
				}
				this.$refs.modal.close();
            }
            catch(err) {
                this.error = err.message;
                console.error("Error sending notificxation: ", err);
            }
        },

        canceled() {
            if(this.onTerminated) {
                this.onTerminated();
            }
        }
    }
}
</script>

<template>
    <modal ref="modal" class="modal-test-notification" title="Tester l'envoi d'une notification" :buttons="buttons" @cancel="canceled">
		<div class="email-form">
        	<label>Email</label>
			<field-input label="Email" v-model="email"/>
		</div>
        <div v-if="error" class="error" v-html="error"/>
    </modal>
</template>

<style lang="scss">
    @import "../../node_modules/@snark/backoffice/src/sass/variables.scss";

    .modal-test-notification {
        .modal-container {
            width: 600px;
        }

        .error {
            margin: 10px 0;
            color: #e33232;
        }

		.email-form {
			label {
				color: $title2Color;
				margin-bottom: 5px;
			}
		}
    }
</style>
